import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from './Pages/About';
import Contacts from './Pages/Contacts';
import Services from './Pages/Services';
import Products from './Pages/Products';
import PoliticsAndPrivacy from './Pages/PoliticsAndPrivacy';
import CookieConsent, { Cookies } from "react-cookie-consent"; 

function App() {

  return (
    <div> 
      <div> 
      <CookieConsent location="bottom"  buttonText="Aceitar" cookieName="myAwesomeCookieName2"
          style={{ background: "#ffff" }}  buttonStyle={{ color: "#ffff", fontSize: "13px", backgroundColor:"#0473eb" }} expires={150} >
          
         <div>
            <div className='d-flex' >
               <strong  style={{fontSize: "16px", color:"#000"}} className='text-black'>Este site utiliza cookies para melhorar a experiência do usuário.</strong>{" "} 
               <a href='/privacy_and_policy' className='text-primary'> Saiba mais !</a>
            </div> 
            <div  style={{maxWidth:"800px"}}>
               <small style={{fontSize: "13px", color:"#5b5b5b"}} >Utilizamos cookies para coletar e analisar informações sobre o desempenho e o uso do site, para fornecer funções de redes sociais e para melhorar e personalizar o conteúdo.</small>
            </div>
         </div>
      </CookieConsent>
      </div>
      <Router>
        <Routes> 
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contacts' element={<Contacts />} /> 
          <Route path='/products' element={<Products />} />
          <Route path='/privacy_and_policy' element={<PoliticsAndPrivacy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App; 